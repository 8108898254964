import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Apollo } from "apollo-angular";
import { latLng, Map as LeafMap, MapOptions, marker, tileLayer } from "leaflet";
import * as _ from "lodash";
import { chain } from "lodash";
import * as moment from "moment";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter } from "rxjs/operators";
import { User } from "../accessibility-users/user.entity";
import { grants } from "../app-grant-config";
import { AuthService } from "../auth.service";
import { BlockedCalendarWeek } from "../blocked-calendar-weeks-dialog/blocked-calendar-week.entity";
import { BlockedCalendarWeeksDialogComponent } from "../blocked-calendar-weeks-dialog/blocked-calendar-weeks-dialog.component";
import {
  DateGroupCommentDialogComponent,
  DateGroupCommentDialogComponentData,
} from "../date-group-comment-dialog/date-group-comment-dialog.component";
import {
  DateGroupResponsibleDialogComponent,
  DateGroupResponsibleDialogComponentData,
} from "../date-group-responsible-dialog/date-group-responsible-dialog.component";
import { DateGroupsDialogComponent } from "../date-groups-dialog/date-groups-dialog.component";
import {
  DateStackDialogComponent,
  DateStackDialogComponentData,
} from "../date-stack-dialog/date-stack-dialog.component";
import { DialogService } from "../dialog.service";
import { EntityManager, EntityQueryFilterOperator } from "../entity.service";
import { GrantService } from "../grant.service";
import { ImageDialogComponent } from "../image-dialog/image-dialog.component";
import { MetacomService } from "../metacom.service";
import { PageService } from "../page.service";
import {
  PlanningHumanResource,
  PlanningProjectItem,
} from "../picklist-overview/planning-project-item.entity";
import {
  ProjectShortcutsDialogComponent,
  ProjectShortcutsDialogComponentModel,
} from "../project-shortcuts-dialog/project-shortcuts-dialog.component";
import { Project } from "../project/project.entity";
import { SelectDialogComponent } from "../select-dialog/select-dialog.component";
import { StorageObject, StorageService } from "../storage.service";
import { UrlOpenService } from "../url-open.service";
import { YearPlanningLineCalculator } from "../year-planning-lines-dialog/year-planning-line.calculator";
import { YearPlanningLine } from "../year-planning-lines-dialog/year-planning-line.entity";
import { YearPlanningLinesDialogComponent } from "../year-planning-lines-dialog/year-planning-lines-dialog.component";
import { YearPlanningModifyWeekDialogComponent } from "../year-planning-modify-week-dialog/year-planning-modify-week-dialog.component";
import { createCustomLeafletPin } from "./custom-leaflet-pin";
import {
  DateGroup,
  DateGroupContainer,
  DateGroupStackStructure,
  LogicField,
  LogicFieldValue,
  ProjectDateCombo,
} from "./entities/date-entities";
import { YearPlanningLogicFieldVisitor } from "./year-planning.logic";
import {
  yearPlanningQuery,
  YearPlanningQueryData,
} from "./year-planning.query";
import YearPlanningReact from "./YearPlanning";

export interface MetacomServiceOpenLine {
  _rowid?: string;
  project: string;
  aantal: string;
}

@Component({
  selector: "app-year-planning",
  templateUrl: "./year-planning.component.html",
  styleUrls: ["./year-planning.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearPlanningComponent implements OnInit {
  projectLeaders: ProjectLeader[];
  buyAdvisors: User[];
  salesEmployees: User[];
  projectMentors: User[];

  data?: YearPlanningQueryData;

  map: MapOptions = {
    layers: [
      tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "...",
      }),
    ],
    zoom: 7,
    trackResize: true,
    preferCanvas: true,
    center: latLng(52.132633, 5.2912659),
  };

  lineMap: Map<string, YearPlanningLine>;
  dateGroups: DateGroup[];
  queryModel = new YearPlanningQueryModel();
  queryChanged: Subject<any> = new Subject<any>();
  selectedDateColumnsLabel: string;
  dateColumnStorage: DateColumnStorage;
  dateRangeStorage: DateRangeStorage;
  employeeFilterStorage: EmployeeFilterStorage;
  restrictedProjectIds?: string[];

  get mode() {
    return this.route.snapshot.paramMap.get("mode") || "list";
  }

  protected get weekFrom() {
    return (
      this.route.snapshot.paramMap.get("weekFrom") ||
      (this.dateRangeStorage.value ? this.dateRangeStorage.value.from : null) ||
      moment().add(-14, "w").format(YearPlanningLineCalculator.WEEK_FORMAT)
    );
  }

  protected get weekTo() {
    return (
      this.route.snapshot.paramMap.get("weekTo") ||
      (this.dateRangeStorage.value ? this.dateRangeStorage.value.to : null) ||
      moment().add(20, "w").format(YearPlanningLineCalculator.WEEK_FORMAT)
    );
  }

  protected get projectLeaderId() {
    return (
      this.route.snapshot.paramMap.get("projectLeaderId") ||
      (this.employeeFilterStorage.value &&
      this.employeeFilterStorage.value.projectLeaderId
        ? this.employeeFilterStorage.value.projectLeaderId
        : null) ||
      "all"
    );
  }

  protected get buyAdvisorId() {
    return (
      this.route.snapshot.paramMap.get("buyAdvisorId") ||
      (this.employeeFilterStorage.value &&
      this.employeeFilterStorage.value.buyAdvisorId
        ? this.employeeFilterStorage.value.buyAdvisorId
        : null) ||
      "all"
    );
  }

  protected get salesEmployeeId() {
    return (
      this.route.snapshot.paramMap.get("salesEmployeeId") ||
      (this.employeeFilterStorage.value &&
      this.employeeFilterStorage.value.salesEmployeeId
        ? this.employeeFilterStorage.value.salesEmployeeId
        : null) ||
      "all"
    );
  }

  protected get projectMentorId() {
    return (
      this.route.snapshot.paramMap.get("projectMentorId") ||
      (this.employeeFilterStorage.value &&
      this.employeeFilterStorage.value.projectMentorId
        ? this.employeeFilterStorage.value.projectMentorId
        : null) ||
      "all"
    );
  }

  get showAll() {
    return this.grantService.varIs(grants.year_planning.show_all, "true");
  }

  get showAddress() {
    return this.grantService.varIs(grants.year_planning.show_adress, "true");
  }

  get canEditLines() {
    return this.grantService.varIs(
      grants.year_planning.edit_year_planning_lines,
      "true"
    );
  }

  get canEditDateGroups() {
    return this.grantService.varIs(
      grants.year_planning.edit_date_groups,
      "true"
    );
  }

  get canEditBlockedWeeks() {
    return this.grantService.varIs(
      grants.year_planning.edit_blocked_calendar_weeks,
      "true"
    );
  }

  get productionThreshold() {
    return this.grantService.var<number>(
      grants.year_planning.production_hours_highlight_threshold,
      0
    );
  }

  get showBuildingWeeks() {
    return (
      this.grantService.varIs(
        grants.year_planning.edit_building_weeks,
        "true"
      ) ||
      this.grantService.varIs(grants.year_planning.show_building_weeks, "true")
    );
  }

  get disableDates() {
    return this.grantService.varIs(grants.year_planning.disable_dates, "true");
  }

  get columnsInclude(): string[] {
    return this.grantService
      .var(grants.year_planning.columns_include, "")
      .split(",")
      .filter((column) => !!column);
  }

  get columnsExclude(): string[] {
    return this.grantService
      .var(grants.year_planning.columns_exclude, "")
      .split(",")
      .filter((column) => !!column);
  }

  get editColumnsInclude(): string[] {
    return this.grantService
      .var(grants.year_planning.edit_columns_include, "")
      .split(",")
      .filter((column) => !!column);
  }

  get editColumnsExclude(): string[] {
    return this.grantService
      .var(grants.year_planning.edit_columns_exclude, "")
      .split(",")
      .filter((column) => !!column);
  }

  get highLightProjectId() {
    return this.route.snapshot.queryParams["highlightProjectId"];
  }

  canViewColumn(column: string): boolean {
    return (
      (this.columnsInclude.length === 0 ||
        this.columnsInclude.some((c) => c === column)) &&
      !this.columnsExclude.some((c) => c === column)
    );
  }

  canEditColumn(column: string): boolean {
    return (
      (this.columnsInclude.length === 0 ||
        this.columnsInclude.some((c) => c === column)) &&
      !this.columnsExclude.some((c) => c === column)
    );
  }

  protected get projectService() {
    return this.entities.get(Project);
  }

  protected get userService() {
    return this.entities.get(User);
  }

  protected get yearPlanningLineService() {
    return this.entities.get(YearPlanningLine);
  }

  protected get blockedCalendarWeekService() {
    return this.entities.get(BlockedCalendarWeek);
  }

  protected get dateGroupService() {
    return this.entities.get(DateGroup);
  }

  protected get planningProjectItemService() {
    return this.entities.get(PlanningProjectItem);
  }

  protected get planningHumanResourceService() {
    return this.entities.get(PlanningHumanResource);
  }

  constructor(
    readonly http: HttpClient,
    readonly entities: EntityManager,
    protected readonly apollo: Apollo,
    protected readonly router: Router,
    protected readonly route: ActivatedRoute,
    public readonly metacom: MetacomService,
    protected readonly dialogService: DialogService,
    protected readonly authService: AuthService,
    protected readonly grantService: GrantService,
    protected readonly pageService: PageService,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly storageService: StorageService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {
    this.changeDetector.detach();
  }

  render(scrollTo?: number) {
    const root = document.getElementById("react-container");

    if (root) {
      ReactDOM.render(
        React.createElement(YearPlanningReact, {
          scrollTo: scrollTo,
          original: this,
        }),
        root
      );
    }
  }

  ngOnInit() {
    this.bootstrap();
  }

  toggleMode = () =>
    this.updateRoute({ mode: this.mode === "list" ? "map" : "list" });
  queryDirty = () => this.queryChanged.next({});

  async bootstrap() {
    this.dateColumnStorage = this.storageService.make(DateColumnStorage);
    this.employeeFilterStorage = this.storageService.make(
      EmployeeFilterStorage
    );
    this.dateRangeStorage = this.storageService.make(DateRangeStorage);

    if (this.disableDates) {
      this.dateColumnStorage.clear();
    }

    await Promise.all([
      this.mapLines(),
      this.fetchDateGroups(),
      this.fetchAllowedProjects(),
      this.fetchProjectLeaders(),
      this.fetchBuyAdvisors(),
      this.fetchSalesEmployees(),
      this.fetchProjectMentors(),
    ]);

    this.queryChanged
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter(
          () =>
            moment(
              this.queryModel.weekFrom,
              YearPlanningLineCalculator.WEEK_FORMAT,
              true
            ).isValid() &&
            moment(
              this.queryModel.weekTo,
              YearPlanningLineCalculator.WEEK_FORMAT,
              true
            ).isValid()
        )
      )
      .subscribe(() => this.updateRoute());

    this.route.params.subscribe(() => this.onRouteUpdated());
    this.changeDetector.detectChanges();
  }

  async updateRoute(delta?: YearPlanningQueryModel) {
    Object.assign(this.queryModel, delta || {});

    this.saveStores();

    return await this.router.navigate([
      "planning/year-planning",
      this.queryModel.mode,
      this.queryModel.weekFrom,
      this.queryModel.weekTo,
      this.queryModel.projectLeaderId,
      this.queryModel.buyAdvisorId,
      this.queryModel.salesEmployeeId,
      this.queryModel.projectMentorId,
    ]);
  }

  scrollToToday() {
    const element = document.getElementsByClassName("active-week").item(0);

    if (element) {
      element.scrollIntoView();
    }
  }

  leafletReady(map: LeafMap) {
    setInterval(() => map && map.invalidateSize({ animate: true }), 500);
  }

  async resetDataStore() {
    this.dateRangeStorage.clear();

    await this.router.navigate(["planning/year-planning"]);
    location.reload();
  }

  saveStores() {
    if (this.queryModel.weekFrom && this.queryModel.weekTo) {
      this.dateRangeStorage.value = {
        from: this.queryModel.weekFrom,
        to: this.queryModel.weekTo,
      };
    }

    this.employeeFilterStorage.value = {
      projectLeaderId: this.queryModel.projectLeaderId,
      projectMentorId: this.queryModel.projectMentorId,
      buyAdvisorId: this.queryModel.buyAdvisorId,
      salesEmployeeId: this.queryModel.salesEmployeeId,
    };
  }

  async showBuildingWeekDialog(project: Project) {
    if (this.showBuildingWeeks) {
      const result = await this.dialogService.open(
        this.changeDetector,
        YearPlanningModifyWeekDialogComponent,
        {
          data: project,
          clickOutsideToClose: false,
          escapeToClose: false,
        }
      );

      if (result === "save") {
        await this.fetch();
      }
    }
  }

  async editComments(project: Project, group: DateGroupContainer) {
    if (
      this.grantService.varIs(
        grants.year_planning.set_date_comments,
        "true",
        false
      )
    ) {
      const data = new DateGroupCommentDialogComponentData(project, group);
      const result = await this.dialogService.open(
        this.changeDetector,
        DateGroupCommentDialogComponent,
        { data, clickOutsideToClose: false, escapeToClose: false }
      );

      if (result === "save") {
        await this.fetchDateGroups();
        await this.fetch();
      }
    }
  }

  async editResponsible(project: Project, group: DateGroupContainer) {
    if (
      this.grantService.varIs(
        grants.year_planning.set_date_responsibles,
        "true",
        false
      )
    ) {
      const data = new DateGroupResponsibleDialogComponentData(project, group);
      const result = await this.dialogService.open(
        this.changeDetector,
        DateGroupResponsibleDialogComponent,
        { data, clickOutsideToClose: false, escapeToClose: false }
      );

      if (result === "save") {
        await this.fetchDateGroups();
        await this.fetch();
      }
    }
  }

  async editDateStack(project: Project, stack: DateGroupStackStructure) {
    if (
      this.grantService.varIs(grants.year_planning.set_dates, "true", false)
    ) {
      const data = new DateStackDialogComponentData(project, stack);
      const result = await this.dialogService.open(
        this.changeDetector,
        DateStackDialogComponent,
        { data, clickOutsideToClose: false, escapeToClose: false }
      );

      if (result === "save") {
        await this.fetchDateGroups();
        await this.fetch();
      }
    }
  }

  async openColumns() {
    const result = await this.dialogService.open(
      this.changeDetector,
      SelectDialogComponent,
      {
        data: {
          items: this.dateGroups.filter((item) => !item.alwaysVisible),
          isMultiple: true,
          disableSelectAll: true,
        },
        clickOutsideToClose: false,
        escapeToClose: false,
      }
    );

    if (result instanceof Array) {
      this.saveDateColumns();
      await this.fetch();
    }
  }

  async openDates() {
    const result = await this.dialogService.open(
      this.changeDetector,
      DateGroupsDialogComponent,
      { clickOutsideToClose: false, escapeToClose: false }
    );

    if (result === "save") {
      await this.fetchDateGroups();
      await this.fetch();
    }
  }

  async openLines() {
    const result = await this.dialogService.open(
      this.changeDetector,
      YearPlanningLinesDialogComponent,
      { clickOutsideToClose: false, escapeToClose: false }
    );

    if (result === true) {
      await this.mapLines();
      await this.fetch();
    }
  }

  async openBlockedCalendarWeeks() {
    const result = await this.dialogService.open(
      this.changeDetector,
      BlockedCalendarWeeksDialogComponent,
      { clickOutsideToClose: false, escapeToClose: false }
    );

    if (result === true) {
      await this.mapLines();
      await this.fetch();
    }
  }

  async openShortcuts(project: Project) {
    if (project.id) {
      return this.dialogService.open(
        this.changeDetector,
        ProjectShortcutsDialogComponent,
        {
          data: new ProjectShortcutsDialogComponentModel(project, {
            subject: project.id,
            description: project.description,
          }),
        }
      );
    }
  }

  async onRouteUpdated() {
    this.queryModel = {
      mode: this.mode,
      weekFrom: this.weekFrom,
      weekTo: this.weekTo,
      projectLeaderId: this.projectLeaderId,
      buyAdvisorId: this.buyAdvisorId,
      salesEmployeeId: this.salesEmployeeId,
      projectMentorId: this.projectMentorId,
    };

    await this.fetch();
  }

  focusMarker(project: Project) {
    this.map.zoom = this.map.zoom < 9 ? 9 : this.map.zoom;
    this.map.center = latLng(
      parseFloat(project.longitude),
      parseFloat(project.latitude)
    );
  }

  composeNavigationUrl(project: Project) {
    return `https://maps.google.nl/maps/?daddr=${project.longitude}+${project.latitude}`;
  }

  openNavigation(project: Project) {
    this.urlOpenService.open(this.composeNavigationUrl(project));
  }

  openImage(project: Project) {
    const url = `volume/house-image/${project.id}`;

    return this.dialogService.open(this.changeDetector, ImageDialogComponent, {
      data: { url, title: `${project.id} - ${project.description}` },
    });
  }

  openService(project: Project) {
    this.urlOpenService.openNew({
      url: `#/service/overview/${project.id}`,
      forceNewTab: true,
    });
  }

  async fetchItems() {
    const filters: any[] = [
      {
        field: "buildingWeek",
        operator: "Between",
        valueComplex: [this.weekFrom, this.weekTo],
      },
    ];

    if (this.projectLeaderId !== "all") {
      filters.push({
        field: "projectLeaderId",
        operator: "Equal",
        value: `${this.projectLeaderId}`,
      });
    }

    if (this.buyAdvisorId !== "all") {
      filters.push({
        field: "buyAdvisorId",
        operator: "Equal",
        value: `${this.buyAdvisorId}`,
      });
    }

    if (this.salesEmployeeId !== "all") {
      filters.push({
        field: "salesEmployeeId",
        operator: "Equal",
        value: `${this.salesEmployeeId}`,
      });
    }

    if (this.projectMentorId !== "all") {
      filters.push({
        field: "projectMentorId",
        operator: "Equal",
        value: `${this.projectMentorId}`,
      });
    }

    const includeDates = !!this.dateGroups.find((e) => e.isActiveCheck);

    const { data } = await this.apollo
      .query<YearPlanningQueryData>({
        query: yearPlanningQuery,
        variables: {
          filters,
          includeDates,
        },
      })
      .toPromise();

    this.evaluate(
      data.items.map((_item) => this.projectService.fromGraphQL(_item))
    );
  }

  async fetch() {
    const scrollContainer = document.querySelector(".yp-scrollable");
    const scrollTop = scrollContainer ? scrollContainer.scrollTop : 0;

    this.data = null;
    this.changeDetector.detectChanges();

    await this.fetchItems();

    this.changeDetector.detectChanges();
    this.render(scrollTop);
  }

  protected acronym(value: string) {
    const matches = (value || "").match(/\b(\w)/g);
    return matches ? matches.join("") : "";
  }

  protected evaluate(data: Project[]) {
    const output: any = { items: data };

    if (!this.showAll) {
      data = data.filter((item) => !item.id.startsWith("6"));
    }

    if (this.restrictedProjectIds) {
      output.items = data.filter(
        (p) =>
          !p.salesEmployeeId || this.restrictedProjectIds.indexOf(p.id) >= 0
      );
    }

    output.items = Array.from(this.padEmptyWeeks(output.items));
    const groups = _.groupBy(output.items, (d) => d.buildingWeek);
    const weeks = Object.keys(groups).map((k) => groups[k]);

    const productionMap = weeks
      .map((w) => ({
        first: _.first(w),
        score: _.sumBy(w, (z) => z.score),
      }))
      .filter((e) => e.score > 0);

    productionMap.forEach((w, i) => {
      const part = _.slice(productionMap, i, i + 3),
        partsWithScore = part.filter((p) => p.score > 0).length;
      w.first.scoreMovingAverage =
        _.sumBy(part, (d) => d.score) / partsWithScore;
    });

    for (const week of weeks) {
      _.first(week).isNewWeek = true;

      const productionHighlight =
        this.productionThreshold > 0
          ? _.first(week).scoreMovingAverage > this.productionThreshold
          : false;

      for (const item of week) {
        if (item.isNewWeek) {
          item.yearPlanningLine = this.lineMap.get(item.buildingWeek);
        }

        item.foundationAbbreviation = item.foundation
          ? item.foundation.charAt(0).toUpperCase()
          : "";

        item.heatingAbbreviation = item.heating
          ? item.heating.charAt(0).toUpperCase()
          : "";

        item.productionHighlight = productionHighlight;
        item.isActiveWeek =
          item.buildingWeek ===
          moment().format(YearPlanningLineCalculator.WEEK_FORMAT);

        item.markerColor = this.getColorHex(item.projectLeaderId);
        item.marker = this.projectMarker(item);

        if (item.region === "n.v.t.") {
          item.id = null;
        }

        item.dateGroupContainers = [];
        item.dateGroupContainers = this.assembleGroupContainers(item);

        if (item.id && item.score) {
          item.scoreCategory = Math.round(item.score / 100.0);
        }
      }
    }

    output.count = output.items.filter((e) => !!e.id).length;

    this.data = output;
    this.pageService.setTitle(`Jaarplanning (${output.count})`);
  }

  protected async mapLines() {
    const blockedWeeks = await this.blockedCalendarWeekService.query();

    if (!blockedWeeks.hasError()) {
      const lines = await this.yearPlanningLineService.query();

      if (!lines.hasError()) {
        this.lineMap = new YearPlanningLineCalculator(blockedWeeks.value).map(
          lines.value
        );
      }
    }
  }

  protected async fetchDateGroups(): Promise<void> {
    const response = await this.dateGroupService.query({
      orders: [{ field: "orderId", direction: "ASC" }],
      relations: ["dates", "logicFields"],
    });

    if (!response.hasError()) {
      response.value.forEach((group) => {
        group.isChecked = this.dateColumnStorage.isChecked(group.id);
        group.columns = group.description.split(",");
      });

      // Filter date groups with permissions
      const grantedDateGroups = response.value.filter((group) =>
        group.columns.some((column) => this.canViewColumn(column))
      );

      grantedDateGroups.forEach((group) => {
        group.columns = group.columns.filter((column) =>
          this.canViewColumn(column)
        );
        group.stackStructure = Object.values(
          _.groupBy(group.__dates__, (date) => date.stackId)
        )
          .filter((__, index) => !!group.columns[index])
          .map((groupedDates, index) => ({
            id: `${index}`,
            title: `${group.label} - ${group.columns[index]}`,
            dates: chain(groupedDates)
              .orderBy((date) => date.orderId, "asc")
              .map((date) => ({
                date,
                key: this.getDateKey(date.id, date.isSynced),
              }))
              .value(),
          }));
      });

      this.dateGroups = this.disableDates ? [] : grantedDateGroups;
      this.saveDateColumns();
    }
  }

  protected getDateKey(dateId: string, isSynced: boolean) {
    return `${dateId}_${isSynced ? "synced" : "set"}`;
  }

  protected saveDateColumns() {
    const checked = this.dateGroups.filter((e) => e.isActiveCheck);

    this.dateColumnStorage.value = checked.map((g) => g.id);
    this.selectedDateColumnsLabel =
      checked.map((e) => e.label).join(", ") || "Kiezen";
  }

  protected assembleGroupContainers(project: Project): DateGroupContainer[] {
    const dateValues =
      (project.cachedValue &&
        project.cachedValue.dateValues &&
        project.cachedValue.dateValues.reduce(
          (values, currentValue) => ({
            ...values,
            [this.getDateKey(currentValue.dateId, currentValue.isSynced)]:
              currentValue,
          }),
          {}
        )) ||
      {};

    if (!project.id) {
      return this.dateGroups
        .filter((e) => e.isActiveCheck)
        .map((group) => ({
          group,
          stacks: [],
          comments: [],
          responsible: null,
          activeComment: null,
          logicFields: [],
        }));
    }

    return this.dateGroups
      .filter((e) => e.isActiveCheck)
      .map((group) => {
        const stacks = group.stackStructure
          .map((stack) => ({
            ...stack,
            editable: this.canEditColumn(group.label),
            dates: stack.dates
              .map((date) => ({
                ...date,
                value: dateValues[date.key],
              }))
              .map((date) => ({
                ...date,
                isValid:
                  !!date.value &&
                  !!date.value.value &&
                  !isNaN(new Date(date.value.value).getTime()) &&
                  (date.date.visibleOnDatePassed
                    ? this.isDatePassed(date.value.value)
                    : true),
              })),
          }))
          .map((stack) => {
            const datesOrderedById = _.orderBy(
              stack.dates,
              (sd) => sd.date.orderId,
              "desc"
            );

            return {
              ...stack,
              active:
                _.first(datesOrderedById.filter((st) => st.isValid)) ||
                _.last(datesOrderedById),
            };
          });

        const comments = (
          (project.cachedValue && project.cachedValue.dateGroupComments) ||
          []
        ).filter((comment) => comment.dateGroupId === group.id);

        return {
          group,
          stacks,
          responsible: (
            (project.cachedValue &&
              project.cachedValue.dateGroupResponsibles) ||
            []
          ).find((dr) => dr.dateGroupId === group.id),
          comments,
          activeComment: _.first(
            _.orderBy(comments, (c) => c.createdAt, "desc")
          ),
          logicFields: this.getLogicFields(
            group.__logicFields__ || [],
            (project.cachedValue && project.cachedValue.logicFieldValues) || []
          ),
        };
      });
  }

  protected isDatePassed(date: any) {
    return moment(date).isBefore(moment());
  }

  protected getLogicFields(fields: LogicField[], values: LogicFieldValue[]) {
    return chain(fields)
      .groupBy((g) => g.fieldId)
      .map((items, key) => ({
        visitor: new YearPlanningLogicFieldVisitor(items),
        field: (values.find((v) => v.fieldId === key) || { value: null }).value,
      }))
      .map((dto) => dto.visitor.active(dto.field))
      .filter((field) => !!field)
      .value();
  }

  protected latest(dates: ProjectDateCombo[]) {
    return _.first(
      _.orderBy(
        dates,
        [
          (d) => (d.isValid ? new Date(d.value.value) : new Date(0)),
          (d) => d.date.orderId,
        ],
        ["desc", "desc"]
      )
    );
  }

  protected sortDates(dates: ProjectDateCombo[]) {
    return _.orderBy(dates, (d) => d.date.orderId, "asc");
  }

  protected getColorHex(seed: any) {
    let color: any = Math.floor(
      Math.abs(Math.sin(seed * 1.123456) * 16777215) % 16777215
    );
    color = color.toString(16);

    while (color.length < 6) {
      color = "0" + color;
    }

    return `#${color}`;
  }

  protected projectMarker(project: Project) {
    try {
      const popup = [
        `<strong>${project.id}</strong>`,
        project.buildingWeek,
        project.description,
        (project.cachedValue && project.cachedValue.projectLeader
          ? project.cachedValue.projectLeader.name
          : null) || "?",
        `<a href="${this.composeNavigationUrl(
          project
        )}" target="_blank">Routeplanner</a>`,
      ]
        .filter((str) => !!str)
        .join(" &mdash; ");

      return project.latitude && project.longitude
        ? marker(latLng(<any>project.longitude, <any>project.latitude), {
            interactive: true,
            icon: createCustomLeafletPin(project.markerColor),
          }).bindPopup(popup)
        : null;
    } catch (e) {
      return null;
    }
  }

  protected *padEmptyWeeks(data: Project[]) {
    let cursorDate = moment(
      this.weekFrom,
      YearPlanningLineCalculator.WEEK_FORMAT
    );

    for (const project of data) {
      const date = moment(
        project.buildingWeek,
        YearPlanningLineCalculator.WEEK_FORMAT
      );
      const diffInWeeks = Math.floor(date.diff(cursorDate, "w")) - 1;

      if (diffInWeeks > 0 && diffInWeeks < 10) {
        yield* Array.apply(null, Array(diffInWeeks)).map((v, i) => ({
          buildingWeek: cursorDate
            .clone()
            .add(i + 1, "w")
            .format(YearPlanningLineCalculator.WEEK_FORMAT),
        }));
      }

      cursorDate = date.clone();

      yield project;
    }
  }

  protected async fetchAllowedProjects() {
    if (!this.showAll) {
      const response = await this.planningProjectItemService.query({
        filters: [
          {
            field: "humanResourceNumber",
            operator: EntityQueryFilterOperator.Equal,
            value:
              this.authService.user.planningUserId || this.authService.user.id,
          },
        ],
        select: ["projectId"],
      });

      if (!response.hasError()) {
        this.restrictedProjectIds = chain(response.value || [])
          .map((plan) => plan.projectId)
          .uniq()
          .value();
      }
    }
  }

  protected async fetchProjectLeaders() {
    const response = await this.userService.query({
      filters: [
        {
          field: "isProjectLeader",
          operator: EntityQueryFilterOperator.Equal,
          value: "true",
        },
      ],
    });

    if (!response.hasError()) {
      this.projectLeaders = response.value.map(
        (e) =>
          new ProjectLeader(
            parseFloat(e.id),
            `${e.name}`,
            this.getColorHex(e.id)
          )
      );
    }
  }

  protected async fetchBuyAdvisors() {
    const response = await this.userService.query({
      filters: [
        { field: "identity", operator: "IsNull", isNot: true },
        { field: "isBuyAdvisor", operator: "Equal", value: "True" },
      ],
      orders: [{ field: "name", direction: "asc" }],
    });

    if (!response.hasError()) {
      this.buyAdvisors = response.value;
    }
  }

  protected async fetchSalesEmployees() {
    const response = await this.userService.query({
      filters: [
        { field: "identity", operator: "IsNull", isNot: true },
        { field: "isSalesEmployee", operator: "Equal", value: "True" },
      ],
      orders: [{ field: "name", direction: "asc" }],
    });

    if (!response.hasError()) {
      this.salesEmployees = response.value;
    }
  }

  protected async fetchProjectMentors() {
    const response = await this.userService.query({
      filters: [
        { field: "identity", operator: "IsNull", isNot: true },
        { field: "isProjectMentor", operator: "Equal", value: "True" },
      ],
      orders: [{ field: "name", direction: "asc" }],
    });

    if (!response.hasError()) {
      this.projectMentors = response.value;
    }
  }

  protected async getLocation() {
    return new Promise((resolve) => {
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) =>
            resolve(`${position.coords.latitude},${position.coords.longitude}`),
          () => resolve(null)
        );
      } else {
        resolve(null);
      }
    });
  }
}

class ProjectLeader {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly color: string
  ) {}
}

class YearPlanningQueryModel {
  mode?: string;
  weekFrom?: string;
  weekTo?: string;
  projectLeaderId?: string;
  buyAdvisorId?: string;
  salesEmployeeId?: string;
  projectMentorId?: string;
}

class DateColumnStorage extends StorageObject<string[]> {
  key = "yp-date-column-filters";
  defaultValue = [];

  isChecked(id: string) {
    return this.value.indexOf(id) !== -1;
  }
}

class DateRangeStorage extends StorageObject<{ from: string; to: string }> {
  key = "yp-date-range-storage";
  defaultValue = null;
}

class EmployeeFilterStorage extends StorageObject<{
  projectLeaderId: string;
  buyAdvisorId: string;
  salesEmployeeId: string;
  projectMentorId: string;
}> {
  key = "yp-employee-filter-storage";
  defaultValue = null;
}
